import { createApp, h } from 'vue'
import LoadScript from 'vue-plugin-load-script'
//import Router from 'vue-router'
import routes from './routes'
//import App from './App.vue'
//import OutageMap from './views/OutageMap.vue'
import NotFoundPage from './views/NotFoundPage.vue'
import {log} from "../public/logger";

const SimpleRouterApp = {
   data: () => ({
      currentRoute: window.location.pathname
   }),
   
   computed: {
      ViewComponent() {
         // look for direct match
         log( `currentRoute = ${this.currentRoute}` );
         let matchingPage = routes[this.currentRoute] || NotFoundPage;
         // look for wildcard match
         if ( NotFoundPage === matchingPage ) {
            log( 'no specific match, trying wildcard' );
            for ( var route in routes ) {
//               // eslint-disable-next-line
               const routeName = route.replace('*', '.*');
               log( `routeName = ${routeName}` );
               const routeRegexp = new RegExp( `^${routeName}$` );
               if ( routeRegexp.test(this.currentRoute) ) {
                  log( '   matches' );
                  matchingPage = routes[route];
               }
            }
         }
         log( `matchingPage = ${matchingPage}` );
         return require(`./views/${matchingPage}.vue`).default
      }
   },
   
   render() {
      return h(this.ViewComponent)
   },
   
   created() {
      window.addEventListener('popstate', () => {
         this.currentRoute = window.location.pathname
      })
   }
}


createApp(SimpleRouterApp).use(LoadScript)/*.use(Router)*/.mount('#app')

/*
export default new Router({
   routes: [{
      path: '/outageMap',
      name: 'OutageMap',
      component: OutageMap 
   }, {
      path: '/*outageMap',
      name: 'OutageMap',
      component: OutageMap
   }, {
      path: '/',
      name: 'Home',
      redirect: {name: 'OutageMap'}
   }, {
      path: '*',
      name: 'NotFound',
      component: NotFoundPage
   }],
   mode: 'history' 
});
*/
