<template>
<div id="outageMap">
   <section v-if="errored">
      <p>Sorry, unable to load at this time.</p>
   </section>
   <section v-else>
      <div v-if="loading">Loading...</div>

      <h3>{{title}}</h3>
      <div id="map"></div>
   </section>
</div>
</template>


<script>
import axios from 'axios';
import mitt from 'mitt';
import {log} from "../../public/logger";
import {IGLASS} from "../../public/mbMap";
const emitter = mitt();


export default {
   name: 'OutageMap',
   data() {
      return { 
         title: 'Outage Map',
         loading: true,
         errored: false
      }
   },

   methods : {
      getEpochMillisecStr() {
        const now = new Date();
        return now.valueOf().toString();
      },

      getDomainPath() {
         log( 'hostname =' + window.location.hostname );
         return window.location.hostname;
      },

      getCust() {
         log( 'pathname =' + window.location.pathname );
         let pathname = window.location.pathname.substring(1);  // remove first slash
         let cust = pathname.substring( 0, pathname.indexOf('/') );
         return cust;
      },

      getEndpoint() {
        return "https://iglass-public.s3.us-east-2.amazonaws.com/"
            + (this.getCust() === "" ? "" : this.getCust() + "/")
            + "outageMapData.json";
      },

      getServiceEndpoint() {
        return "https://iglass-public.s3.us-east-2.amazonaws.com/"
            + (this.getCust() === "" ? "" : this.getCust() + "/")
            + "service.json";
      },

      onError(response) {
            var error = 'Could not fetch map data';
            alert(error);
            log(response);
      },

      async fetchMapData() {
         return axios.get(this.getEndpoint())
//              .then(response => {      // Can do *either* `then()` or `await`
//                  this.mapPoints = response.data
//                  console.table( this.mapPoints );
//                 })
              .catch(error => { console.error( 'error = ' + error ); })
              .finally( () => this.loading = false );
      },
      async fetchServiceData() {
         return axios.get(this.getServiceEndpoint())
              .catch(error => { console.info( 'info = ' + error ); })
              .finally( () => this.loading = false );
      },

      drawMap( geojson, servicejson ) {
         log( '---drawMap ---' );
        if ( geojson.properties.token == null ) {
           log( 'Invalid Mapbox API access token.', 'error' );
           return;
        }

         IGLASS.iglass_maps_mbMap.init( geojson, servicejson );
      },

     poll(fn, interval, maxAttempts) {
       log( 'Start poll...' );
       let attempts = 0;

       const executePoll = async(resolve, reject) => {
         log( '- poll' );
         await fn( attempts === 0 );
         attempts++;

         if (maxAttempts && attempts === maxAttempts) {
           return reject( new Error('Exceeded max attempts') );
         } else {
           setTimeout( executePoll, interval, resolve, reject );
         }
       };

       return new Promise(executePoll);
     },

     async updateMapData( init ) {
       console.log('updateMapData(' + init + ')');  // always log this
       const response = await this.fetchMapData();
       if ( init ) {
          console.log( "Initializing" );
          const response2 = await this.fetchServiceData();
          this.drawMap( response.data, (response2 === undefined) ? null : response2.data );
       } else {
          console.log( "Updating" );
          IGLASS.iglass_maps_mbMap.updateData( response.data );
       }
     },
   },

   created() {
      this.title = 'Service Outage Map';
      emitter.on('ajax-success', this.onError);
   },

   async mounted() {

      this.poll(this.updateMapData,
            10*60*1000,  // load new data every 10 mins
            12 )     // only update for 2 hours
        .then(data => log(data))
        .catch(err => log(err, 'error'));
   }

}
</script>


<style>
#outageMap {
  font-family: 'Open Sans', sans-serif;
}
#map { width: 100%; height: 200px; }

.mapboxgl-popup {
   /*max-width: 200px;*/
   border-radius: 12px;
}
/* mapbox 'offset' doesn't seem to be working properly, override */
.mapboxgl-popup.mapboxgl-popup-anchor-top {
   top: -10px;
}
.mapboxgl-popup.mapboxgl-popup-anchor-bottom {
  top: -28px;
}
.mapboxgl-popup-anchor-top .mapboxgl-popup-content {
   box-shadow: 5px 5px 3px 0 gray;
}
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-content {
   box-shadow: 5px -5px 3px 0 gray;
}
.popupHeader {
   background-color: #99ccff;
   padding: 5px 30px 5px 20px;
   display: flex;
   align-items: center;
   justify-content: center;
   font-weight: bold;
   font-size: larger;
}
.mapboxgl-popup .mapboxgl-popup-content {
   text-align: center;
   font-family: 'Open Sans', sans-serif;
   border-radius: 5px;
   padding: 5px 5px 15px 5px;
}
.mapboxgl-popup .mapboxgl-popup-close-button {
   /* adjust to be inside header bar */
   top: 5px;
   right: 5px;
}
.marker { width:0; height:0; }
.marker  span {
   display:flex;
   justify-content:center;
   align-items:center;
   box-sizing:border-box;
   width: 30px;
   height: 30px;
   color:#fff;
   background: #0080FF;
   border:solid 2px;
   border-radius: 0 70% 70%;
   box-shadow:0 0 2px #000;
   cursor: pointer;
   transform-origin:0 0;
   transform: rotateZ(-135deg);
}

.marker span.green-marker { background: #0AB90A; }
.marker span.yellow-marker { background: #FFFF00; color: #000; }
.marker span.red-marker { background: #7E0000; }
.marker b { transform: rotateZ(135deg); }
</style>
