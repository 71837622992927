
export function log(message, level) {
    if ( level === 'error' ) {
        console.error(message);
    } else if ( level === 'warn' ) {
        console.warn(message);
    } else {

        // in production build, regular logs will be removed
        if ( process.env.NODE_ENV !== 'production' ) {
            console.log(message);
        }
    }
}
