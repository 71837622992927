/*!
 * Copyright InfoStructure Solutions Inc. 2021
 * All rights reserved.  No part of this document may be reproduced in any
 * form without the written permission of the copyright holder.
 */
/* jshint ignore:unused */
/* globals mapboxgl, turf */

import {log} from "./logger";

export var IGLASS = IGLASS || {};

IGLASS.iglass_maps_mbMap = (function() {
   "use strict";
   
   var map;
   var markers = [];
   var polys = [];
   var serviceArea = null;

   /*
    * This function dynamically sets the size of the map based on window size.
    */
   function _setMapSize() {
      let map = document.getElementById('map');
      map.style.height = window.innerHeight - 200 + 'px';
      map.style.width  = window.innerWidth - 50 + 'px';
      log( "Setting map size (w=" + map.style.width + ", h=" + map.style.height + ")" );
   }

   function createMarker( point ) {
      log( "createMarker: " + point.geometry.type );

      if ( point.geometry.type === 'Point' ) {
         // create a HTML element for each feature
         var el = document.createElement('div');
         el.className = 'marker';
         if (point.properties.offline > 0 ) {
            el.innerHTML = '<span><b>' + point.properties.offline + '</b></span>';
         } else {
            el.innerHTML = '<span></span>';
         }

         // make a marker for each feature and add to the map
         //new mapboxgl.Marker({options:{color: marker.properties.rgbColor, anchor: 'bottom'}})
         var alarmTime = new Date( point.properties.start );
         const popup = new mapboxgl.Popup() // add popups
             .setHTML(
                 `<div class="popupHeader"><img src="/wireless-icon-4-24.png">&emsp;Service Outages</div>` +
                 `<h3>${alarmTime.toLocaleString([],{ timeZoneName: 'short'})}</h3>` +
                 `<p>${point.properties.offline} customers affected</p>`
             );
         var marker = new mapboxgl.Marker(el, {'offset': [0, 0]} )
             .setLngLat(point.geometry.coordinates)
             .setPopup(popup)
             .addTo(map);
         markers.push(marker);
      }
      return marker;
   }
   
   function clearMarkers() {
      log( "Clearing markers . . .");
      markers.forEach((marker) => marker.remove());
      markers = [];
   }
   function clearPolys() {
      log( "Clearing polys . . .");
      polys = [];
   }

   function updateData( geojson ) {
      clearMarkers();
      clearPolys();

      // Create Polygons
      if ( Array.isArray(geojson.features) ) {
         geojson.features.forEach(function (feature) {
            log("Feature: " + feature.geometry.type);
            if (feature.geometry.type === 'MultiPoint') {
               var size = feature.geometry.coordinates.length;
               log("Feature size: " + size);
               if (size > 2) {
                  var hull = turf.convex(feature);
                  if (hull === null) {
                     // Only add point
                     log("Hull failed, adding point at [" + feature.geometry.coordinates[0] + "]");
                     createMarker(turf.point(feature.geometry.coordinates[0], feature.properties));
                  } else {
                     polys.push(hull);
                     var pof = hull.geometry.coordinates[0][0];
                     log("Adding ploy point at [" + pof + "]");
                     createMarker(turf.point(pof, feature.properties));
                  }
               } else if (size > 0) {
                  // Only add point
                  if (Array.isArray(feature.geometry.coordinates[0])) {
                     log("Adding point from array at [" + feature.geometry.coordinates[0] + "]");
                     createMarker(turf.point(feature.geometry.coordinates[0], feature.properties));
                  } else {
                     log("Adding point at [" + feature.geometry.coordinates + "]");
                     createMarker(turf.point(feature.geometry.coordinates, feature.properties));
                  }
               } else {
                  log("Skipping");
               }
            }
         });
      }
      let source = map.getSource( 'nodes' )
      if ( source != null ) {
         log( "Updating Polys" );
         source.setData( turf.featureCollection(polys) );
      }
   }

   function init( geojson, servicejson ) {

      var myOptions = {
         container: 'map',
         style: 'mapbox://styles/mapbox/light-v10',
         bounds: geojson.properties.bbox,
         fitBoundsOptions: { padding: 50 },
         accessToken: geojson.properties.token   // Use MapBox API token passed in.
      };

      // Set the size of the map to full screen.
      _setMapSize();

      // Set event to resize the map on window size change
      window.onresize = function( /* event */ ) {
         _setMapSize();
         map.resize();
      };

      map = new mapboxgl.Map( myOptions );

      // Service area
      log( "Checking service area . . .");
      if ( servicejson !== null ) {
         log( "Service area defined.");
         serviceArea = turf.mask(servicejson);
      } else {
         log( "No service area defined.");
      }

      // Navigation
      map.addControl( new mapboxgl.NavigationControl({showCompass: false}), 'top-left' );

      // Add Scale to map
      map.addControl(new mapboxgl.ScaleControl({maxWidth: 80, unit: 'imperial'}), 'bottom-left');

      updateData( geojson );

      // Add Polys
      map.on('load', function() {
         //console.table(evt);  // Restore function(evt) if uncommented.
         if ( serviceArea !== null ) {
            log( "Adding service area . . ." );
            map.addSource( 'servicearea', {'type': 'geojson', 'data': serviceArea} );
            map.addLayer({
               'id': 'servicearea', 'type': 'fill', 'source': 'servicearea',
               'paint': {
                  'fill-opacity': 0.75,
                  'fill-color': '#787886',
                  'fill-outline-color': '#000000',
                  'fill-antialias': true
               }
            });
         }
         log( "Adding polys source . . ." );
         map.addSource( 'nodes', {'type': 'geojson', 'data': turf.featureCollection(polys)} );
         log( "Adding polys layer . . ." );
         map.addLayer({ 'id': 'nodes', 'type': 'fill', 'source': 'nodes',
            'paint': { 'fill-opacity': 0.4, 'fill-color': '#FF0000', 'fill-outline-color': '#000000', 'fill-antialias': true },
            'filter': ['==', '$type', 'Polygon'] });
      });

   }
   
   // Reveal the public API.
   return {
      init: init,
      updateData: updateData
   };

})();
